import React from 'react';
import AuthProvider, {LoginEmail, LogOut} from './AuthProvider';
import Logout from './Logout'
//import ReactDOM from 'react-dom';
import {DragDropContext} from 'react-beautiful-dnd';
import styled from 'styled-components';
import '@atlaskit/css-reset';
import data from './initial-data';
import Column from './column';
import {KanButton} from './addbutton.jsx';
import TrashColumn from './trashcolumn';
import shortid from 'shortid';
import Tooltip from './tooltip';
import ContentEditable from "react-contenteditable";
import {MongoInsert, MongoRetrieve, SyncChecker} from "./MongoRealm.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Login';
import { faStickyNote, faUndo } from '@fortawesome/free-solid-svg-icons';

/*
App.js description + WALL OF CODE WARNING

This App can be considered the parent react component to the rest of the components.
They are all rendered within this app, and this app controls and maintains the app as 
a single page.

WARNING: There is a LOT of code on this page.  When learning react and having trouble getting
specific components to function, all recommendations point to writing the code within
the parent component and pasing callback parameters to the child.  As I got along further,
it became clearer that I could write functions on the same level as the parent and importnat
them, however for the app to maintain functionality within the appropriate time frame,
I left in a lot of the code that make it function.

big to-do: refactor the app to break it down into smaller chunks of code at the parent level,
seperating concerns into neighboring files that are imported into the app.

*/

//To-do - Take all these styled elements and put them into a custom APP.css file
//And then import them into here
const AppBar = styled.div`
  
  background-color: #779BC3;
  padding-top: 0px;
  top: 0;
  max-width: 100%;
  overflow-x: hidden;
  
  `
const AppBarTitle = styled.div`
  text-align: center;
  font-family: "Comfortaa", cursive;
  font-size: 48px;
  color: white;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
`
const SideBarLeft = styled.div`
  margin-top: 10px;
  padding: 12px;
  
  border-right: solid 4px;
  border-color: black;
  margin-bottom: 7px;
  position: flex;
  flex-direction: column;
  
`
const AuthText = styled.div`
  font-size: 6px;
  padding-left: 5px;
  position: absolute;
  top: 10;
  left: 5;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

/*App component - this represents the board object.
Its state matches up with an imported data file (initially the initial-data) file
and then later, the data store that is retrieved from the kanban board based on credentials.
It also has numerous properties that are local to the app.*/
class App extends React.Component {
  //state = data;
  constructor(props){
    super(props);
    this.state = data;
    this.stateList = [];
    this.oldState = null;
    //this.newState = null;
    this.currentTargetId = null;
    this.currentTextChange = null;
    this.currentTargetTitle = null;
    this.auth = false;
    this.interval = null;
    this.email = '';
    this.password = '';
    this.lastUpload = this.state;
    //Unused below
    //this.tasksEmpty = null;
    /*var user = {
      email: '',
      password: '',
      id: '',
    }*/
    

  }
 
  /*
   Add a new Task

   This task adds in a new post-it note to the board and changes the data state
   accordingly to match to the new state.
  */
  addTaskClicked() {

    //Save the previous state
    this.saveBoardState();
    console.log("Created new task.")
    //Create a unique id for the task for the app to utilize
    var taskId = shortid.generate().toString()
    
    //Create a new tasks array with the new task in the 0 index position
    var tasks = {[taskId]: {id: taskId, title: 'New Task', content: 'Click to enter details...'}, ...this.state.tasks}
    //Get the 1st column from the columns array
    var column = this.state.columns['column-1'];
    //Update the column with the new task's Id in its taskIds array
    column.taskIds = [taskId, ...this.state.columns['column-1'].taskIds]
    
    //Create a new board state with the updated tasks array, and the updated columns    
    const newState = {
      tasks: tasks,
      columns:{
        "column-1": column,
        ...this.state.columns,
      },
      
    }

    //Set the new state to the current state
    this.setState(newState)
    
  }

  /* 
  On Drag End
  When drag of a task ends it saves the state of the object list and replaces state.
  
  There is a fairly complex order to this dependent on column dragged to etc.
  The goals it to preserve reordering operations instead of reverting back to the previous state
  */
  onDragEnd = result => {
    //Save the board state
    this.saveBoardState()
    //Make a quick check to see if the arrays are empty
    this.checkTasks()
    //get the information needed from the react-beautiful-dnd result
    const { destination, source, draggableId} = result;
    
    //If there is no destination drop, there is nothing to do and we can reorder as before
    if(!destination) {
      //Undo the last state so we don't have two states in a row
      this.Undo()
      return;
    }

    //Check if the droppableId matches the source it came from
    //And if its index is the same (meaning we dragged to the same place)
    if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index) {
      //Clear the last board state so we don't a duplicate
      this.Undo()
      //If true, the item is in the same position and no change is needed
      return;
    }

    /*If the task is in a new valid location, we make the changes to the board state
    column becomes the the current state column source
    and taskIds creates a new array of the previous tasks in the new order */
    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    //if start and finish columns are the same
    if(start === finish){
    //create a new array of tasks
      const newTaskIds = Array.from(start.taskIds);
    // from this index remove the item we dragged out
    newTaskIds.splice(source.index, 1);
    //then move the taskId from its old index to its new index in the array.
    newTaskIds.splice(destination.index, 0, draggableId);
    //Make a new column with the changed taskIds and gives it the changed task order
    const newColumn = {
      ...start,
      taskIds: newTaskIds,
    };

    //Add the new column to the new state
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newColumn.id]: newColumn,
      }
    }
    //Update the board state to include the updated column
    this.setState(newState);

    }
    //if a task has moved to a different column entirely
    else {
  
    //Create a new array from current task Ids
    const startTaskIds = Array.from(start.taskIds);
    //remove the dragged taskId from the array
    startTaskIds.splice(source.index, 1)
    //Create a new starting column with new array
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };
    //create a new Array
    //and Insert the draggable id in the new array at destination index
    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    //create a new column with new array
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };
    //render new state with same properties, but updated columns map to include
    //the new columns showing the task in the new column in the correct position.
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    //Update the state and re-render the board to the user.
    this.setState(newState);
    
  }
}
  /*
  Save Board State

  This function saves a copy of the board and puts it into a local store
  required for undo oeprations */
  saveBoardState(){
    //Don't save state if there are no changes to the last state
    if (this.state === this.oldState) {
      console.log('no changes detected')
    }
    else {
    //Otherwise save the state into an array of states for Undo use later
    console.log('Saved board state')
    this.stateList.push(this.state)
    //Assign the old state to be whatever the current state is for future checks.
    this.oldState = this.state;
    } 
  }

  Undo(){
    //If there are no previous states, nothing to undo to and we return
    //We also let the user know that text changes are not within scope of this Undo.
    if(this.stateList.length === 0){
      console.log('Nothing to undo - (Only movement and deletions can be undone).')
      return
    }
    else{
      try{
      //If there is a state, we pop it out and set it to the new state
      const newState = this.stateList.pop();
      //Debug
      //console.log('STATELIST:', this.stateList);
      this.setState(newState);
      //this.forceUpdate();
      }
      catch(err){
        console.log("Undo Error:", err)
      }
  }
  }

  //This function is an event handler specifically for changing the board app title.
  handleChange = evt => {
    //When a user changes the board title and clicks off of it, it assigns.
    this.setState({boardTitle: evt.target.value})
  }

  /*
  Handle New Change

  This is an important function that changes our data state 
  to match up with the content editable text input by the user.
  */
  handleNewChange(target, title, text) {
    //DEBUG
    //console.log(`Changing data state for ${target} : ${title} -> ${text}`);
    //To do - rewrite function to use one more parameter to designate between taskTitle and taskContent
    
    //First we check to see if it was a column that was modified - target would be COLUMN
    var columnCheck = (target.slice(0,6))
    
    //If the changes match what was previously there, no action performed
    if ( title === text) {
      console.log("No change needed.");
      return;
    }
    //If it wasn't the column that was changed
    else if (columnCheck !== "column") {
      //save the board state and inform the user
      this.saveBoardState()
      console.log("Updating task content")
      
      //get task from taskList
      var task = this.state.tasks[target];
      
      //if its just task details, make that the content into new text
      //To-do: change taskContent so that it doesn't show that on text hover.
      if(title === "taskContent"){
        task.content = text;

        }
      //otherwise, change the task's titleto be the new text
      else{
        task.title = text;
      }
      //then merge the new task into the current task list
      const newState = {
        tasks: {
          ...this.state.tasks,
          [target]: task

        },
        ...this.state
      }

      //Update state with the updated task list
      this.setState(newState);
      
      return;
      }
      else {
        //make state changes to update the column Title
        console.log("Updating column title")
        //save board state and assign column based on columnid with target
        this.saveBoardState();
        var column = this.state.columns[target];
        //Assign the text to the columns title property
        column.title = text;
         
        //create newState with the modified column
       const newState = {
          ...this.state,
          columns: {
            ...this.state.columns,
            [target]: column
            
          }
          
        }
        //replace the states
        this.setState(newState);
        return;
      }
    }
    
    /*
    Highlight all - when the user focuses on an editable object, 
    all text becomes highlighted
    for easy replacement.
    
    Code solution here was used from a pluralsight tutorial on how to highlight 
    text content in a react app.
    */
    highlightAll = () => {
      setTimeout(() => {
        document.execCommand('selectAll', false, null)
      }, 0)
    }

    /*
    Change Handler

    This event listener works similarly to changing the board title but is used 
    for column titles, task titles and task content
    */
    changeHandler = evt => {
      
      this.setState({[evt.target.name]: evt.target.value})
      //DEBUG to see values in console
      //console.log(evt.target.name, evt.target.value)
    }
    
    /*
    handleLogin

    to-do - seperate this into another Login Handling function file to decrease this file's 
    amount of code.

    Handle Login is an event listener from the login form, that takes the form
    data and processes it to authenticate with the realm app through the auth provider.
    */
    async handleLogin(evt, email, password){
      //Uncomment to Debug
      //console.log("Attempted login with credentials:", email, password)
      
      //Attempt to log in the user with an e-mail
      //If one of the forms is empty - we warn the user that they need more information.
      if (email === '' || password === ''){
        console.log("Insufficient credentials provided. Please try again.")
        
        toast.warn("Insufficient Login Info. Please try again.", 
        {position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        evt.preventDefault()
        return
      }
    

    //If credentials are present
    else{  
    //Todo in the future - when accounts are created within, they should also be set to lowercase
    //All accounts are currently created with lowercase values.
    //Change user input to account for this.
    email = email.toLowerCase();
    //Authenticate with realm using provided credentials
    const result = await LoginEmail(email, password)
    //if the login is successful, inform the user 
        if (result !== false) {
            toast.success("Login was successful!",
            {position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            const newId = result;
            console.log('Updating state.')
            //Take the id variable provided by the auth provider and set it to display
            this.setState({userId: newId});

            //Attempt to retrieve the most current state if the user has board data
            await this.retrieveState(newId, this.state)  //put await back if it stops working
          }
            //If login fails (wrong username and password)
            //Inform the user
            else {
              toast.error("Incorrect Login credentials provided :(",
              {position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                })
              console.log("Incorrect credentials provided.")
              return
            }
          }} //End of the handle Login Function
          
      /*
      Initial state function

      If a user logs in for the first time, instead of retrieving their state,
      we insert default data into the DB as their board.
      This uses the MongoInsert function from MongoRealm file.
      This is called from the retrieve state function below.
      */
      initialState(userId, state){

        MongoInsert(userId, state)
        .then(() => console.log("Initial Board Created."))
        .catch(err => console.log("Something went wrong. ERROR: ", err) )
      }
      
      /*
      Retrieve state function

      This is ran when a user logs into the app.
      When we get a response from MongoRetrieve, if a user's board doesn't exist (because a new user)
      we populate a new board, otherwise we load the current from the DB.
      */
      retrieveState(userId){
        MongoRetrieve(userId)
        .then(response => {
                          //MongoRetrieve will give us Null if the user is logging in for the first time
                          console.log("Received Response.") //DEBUG: add actual response to console 
                          if (response === null) {
                            console.log("No existing board found, populating board with default data")
                            //Here we customize our initial data state to the user because otherwise MongoDB will reject it
                            this.setState({email: this.email})
                            //Add initial data and make the auth true to render the app.
                            this.initialState(userId, this.state)
                            this.setState({auth: true});
                            return
                          }
                          else {
                            //Otherwise set the state to the object sent back by MongoRetrieve
                            //And force auth to true to render the app.
                            this.setState(response);
                            this.setState({auth: true});
                            
                            
                                        }}) 
        .catch(err => console.log("Something went wrong. ERROR: ", err) )

      }
      
      /*
      syncState

      This function calls MongoInsert and uploads the new state into MongoDB. 
      See MongoRealm for more information on it.
      */
      syncState(userId, state){
        MongoInsert(userId, state)
        .then(response => console.log("Upload succeeded.", response))
        .catch(err => console.log("Something went wrong. ERROR: ", err) )
      }
    
      
      /*
      Sync Function

      This creates a timed check every 15 seconds to see if the user's board has been edited.
      If it has, the board will upload a replacement state to the database, if no changes, it will just 
      check again in 15 minutes.
      
      It also turns off when auth is disabled.
      */
      Sync = () => {
        
        //Retrieved from Azund on Stack Overflow question using interval method to create a reoccuring function
        //console.log(lastUploadedState)
        console.log('Listening...')
        //Call the checker function to see if there have been changes check MongoRealm for more on this.
        SyncChecker(this.state.userId, this.lastUpload, this.state)
        
        //If not authenticated, this does nothing
        this.interval = setInterval(() => {
          if (this.state.auth === false){
            //console.log('listening...')
          }
          else{ return (
            //If authenticated we begin checking states and then aligning them after upload to match up
            SyncChecker(this.state.userId, this.lastUpload, this.state),
            this.alignStates()
            )}
        }, 15000)
            return() => {
                //Clear the interval and restart the timer
                clearInterval(this.interval);
            }
          }
      
      //This aligns the states after syncing to the uploaded state.
      alignStates() {
        this.lastUpload = this.state;
      }
      
      //When the app page loads (due to a user logging in), we fire up the sync checking
      componentDidMount() {
            this.Sync()
          }

      //When the app page is going to unmount (because of logout) the sync checking will stop.
      componentWillUnmount() {
        clearInterval(this.interval);
        }
  
      /*
      Log me out function

      Handles logout gracefully, saves the board and resets app to default data.
      */
      LogMeOut() {
        //Update if there are any changes to the board (forces a save) and notifies auth provider to perform logout from Realm
        SyncChecker(this.state.userId, this.lastUpload, this.state);
        LogOut();
        //Changes board to default state and informs the user that their board info was saved.
        this.setState(data);
        toast.success("Logout success - Saved Board",
            {position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                })

        
        return
      }

      /*
      Check Tasks function

      This is a check to see if all columns have no tasks in them.  Its used to check and show a tool tip if all taskIds are length 0.
      */
      checkTasks(){
        const lengthArray = [this.state.columns['column-1'].taskIds.length, this.state.columns['column-2'].taskIds.length, this.state.columns['column-3'].taskIds.length];
        //console.log("Array lengths are:", lengthArray) //Debug
        if (lengthArray.every(function(e){return e === 0})) {
          
          return true; 
        }
        else {
          //console.log("Arrays are not empty!!") //Debug
          return false;
        }
        
      }
  
  //This function renders out the entire Application
  render() {
    //If the user is not yet logged in, then we display a login page
    if (this.state.auth === false) {
    
    return (
    /*
    Toast container shows alerts.  
    We also provide the login form for the user to attempt login with
    */
    <>
    <ToastContainer />
    <AppBar>
    <AppBarTitle>
    Simple Kanban
    </AppBarTitle>
    
    <p/><p/>
    </AppBar>
    {/*Add in our Log In Provider Here*/}
    
    
    <Login
    name={this.state.email}
    password={this.state.password}
    onChange={(evt) => this.changeHandler(evt)}
    onSubmit={(evt) =>  {
    evt.preventDefault()
    this.handleLogin(evt, this.state.email, this.state.password);
    console.log("Attempting log-in...")
    //console.log("Submitted the following:", this.state.email, this.state.password);
    evt.preventDefault()
    }}></Login>
    
    </>
    
    )
    }

    //If the user is logged in, we render the main UI of the app
    if (this.state.auth === true) {
      //To prevent outputing text within tags, adding comment overview here of process
      /*
        When we drop something we were dragging, trigger the above onDragEnd function
        First map the column array out, pass the columnId, to create columns based on id
        And map out tasks based on passing the taskIds of the column
        Finally pass the column details including props into the child column and task objects.
        
        All editable items and buttons have the proper event handlers and listeners set up above to facilitate editing content
        and logout when needed.
        */
    
    return (
      <>
      <ToastContainer />
      <AppBar>
      <Logout onClick={() => this.LogMeOut()}/>
      
      
      <AuthText><AuthProvider></AuthProvider></AuthText>
      
       
      <AppBarTitle>
      <ContentEditable
      html={this.state.boardTitle}
      disabled={false}
      onChange={this.handleChange}
      onFocus={this.onFocus}
      onBlur={this.saveState}
      spellCheck={false}
      />
      </AppBarTitle>
      </AppBar>
      
      <Tooltip tasksEmpty={this.checkTasks()} icon={faStickyNote}/>
      <DragDropContext onDragEnd={this.onDragEnd}>
      
      <Container>
      <SideBarLeft>
      <KanButton name={""} onClick={() => this.addTaskClicked()} topValue={"30px"} icon={faStickyNote} size={"2x"}></KanButton>
      <p></p>
      <KanButton name={""} onClick={() => this.Undo()} topValue={"180px"} icon={faUndo} size={"2x"}></KanButton>
      </SideBarLeft>
      

      {this.state.columnOrder.map(columnId => {
      const column = this.state.columns[columnId];
      const tasksArray = column.taskIds.map(taskId => this.state.tasks[taskId]);
      const tasks = tasksArray.filter(function(element){return element !== undefined;});
      //console.log(tasks);
      return <Column
      class={Column}
      key={column.id} //Warns about this but we need it
      column={column}
      tasks={tasks}
      html={column.title}
      onChange={() => this.handleColumn(column.id)}
      suppressContentEditableWarning={true}
      

      onFocus={(e) => {
                      this.highlightAll();
                      [this.currentTargetId, this.currentTargetTitle] = [e.target.id, e.target.title];
                      //console.log(this.currentTargetId, this.currentTargetTitle);
                      
                      }}
      onBlur={(e) => {this.currentTextChange = e.currentTarget.textContent
                      //console.log(this.currentTextChange)
                      this.handleNewChange(this.currentTargetId, this.currentTargetTitle, this.currentTextChange);
                      }}/>;
      

    })}


    <TrashColumn Class={Column} key={'trashcolumn'} column={this.state.columns['trashcolumn']} tasks={this.state.columns.trashcolumn.taskIds} />
    
    </Container>
    </DragDropContext>
    </> 
    
    ); 
  }

  else {
    /*This should never show if we have done everything right. */
    return (
      <div>404: Your page was not found</div>
    )
  }
}


}

export default App;
