import React from "react";
import styled from "styled-components";
import trashbin from "./img/ClosedTrash.png";

/*
React child component that contains a trash bin icon and some stylings for it.
*/

const Logo = styled.img`
    width: 100%;
    float: right;
    position: sticky;
    padding-top: 1em;
   
`;


export class KanBin extends React.Component {

    render(){
        return (
        
        <Logo src={trashbin}/>
        
        );
    }


}