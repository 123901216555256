import React from 'react';
//import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import LoadingButton from './loadingButton';

/*
Login form component.

Contains the form for the username and password,
probably some extra unnecessary stylings for the components and areas
where password management functionality will be added.

*/

const LoginView = styled.div`

position: relative;
top: 10px;
text-align: center;
margin: auto;
font-family: 'Shadows into Light Two', cursive;
background-color: lightyellow;
border-radius: 5px;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
font-size: 38px;
font-weight: bold;
-webkit-transform: rotate(-4px);
  -o-transform: rotate(-4px);
  -o-transform: rotate(-4px);
input
{
    border: 0px solid #000000;
    border-bottom-width: 1px;
    background-color: transparent;
    font-weight: bold;
}
input[type=text] {
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-family: 'Shadows into Light Two', cursive;
  font-size: 23px;
}
input[type=password] {
  width: 75%;
  padding: 12px 15px;
  margin: 8px 0;
  box-sizing: border-box;
  font-family: 'Shadows into Light Two', cursive;
  font-size: 23px;
  
}
input[type=text]:focus {
    background-color: skyblue;
    border: 0px solid #000000;
    border-bottom-width: 1px;
    
}
input[type=password]:focus {
    background-color: skyblue;
    border: 0px solid #000000;
    border-bottom-width: 1px;
    
}
display: block;
  height:8em;
  width: 8em;
  padding:2em;
button {
    font-family: 'Comfortaa', cursive;
    font-size: 25px;
}
a {
    padding-top: 20px;
    font-size: 20px;
    color: purple;
    cursor: pointer;
    
}
button{
    button
    margin: auto;
    position: absolute;
    top: 100%;
    left: 22%;
    text-align: center;
    margin-top: 25px;
    background-color: #779BC3;
    border-radius: 12px;
    padding: 10px;
    color: white;
    width: 60%;
    font-size: 25px;
    cursor: pointer;
}

`

class Login extends React.Component {
 
  render() {

    // const for React CSS transition declaration
    //Utilizes CSS transitions - to-do: remove. This is an artifact that is not used and will not be needed.
    return (
            <>
            <CSSTransition transitionName="animation" transitionAppear={true} timeout={500} transitionAppearTimeout={500} transitionEnterTimeout={500} transitionLeaveTimeout={300}>
            <LoginView>
            <div value={this.props.email} onChange={this.props.onChange}>
              <Logo />
              <form className={'autocomplete'} spellCheck={false} onSubmit={this.props.onSubmit} >
                <input type='text' name='email' value={this.props.email} onChange={this.props.onChange} placeholder='email' autoComplete={'username'} />
                <input type='password' name='password' value={this.props.password} onChange={this.props.onChange} placeholder='password' autoComplete={'current-password'} />
                <LoadingButton type={'submit'}  ></LoadingButton>
              </form>
              {/*To-do add a link to reset a user's password via e-mail here as a seperate page*/}
                <a href={'#'} onClick={() => alert("Sorry! This feature is still under construction.")}>Forgot your password?</a>
           </div>    
            </LoginView>
           </CSSTransition>
           </>
    )
  }
}

// Space for Logo - Icon - we did not end up using this, but have it available for the future in case we create an actual kanban board icon
class Logo extends React.Component {
  render() {
    return <div className="logo">
                <i className="fa-sticky-note-o" aria-hidden="true"></i>
                <span> Sign In</span>
              </div>
  }
}


export default Login;