
import React from 'react';
import * as Realm from "realm-web";



//Authentication provider to log into the realm app and access the database
//
//Based off of the React Quick Start guide for MongoRealm, and then modified to include
//email/password credentials to allow different users access.

//Establish our app global variables
const REALM_APP_ID = "simple-kanban-api-xhnql";
const app = new Realm.App({ id: REALM_APP_ID });

//Provides user details in the top left corner of the application
function UserDetail({ user }) {
   if (user === null ){
     return <div>Not logged in.</div>
   }

  //todo - show user e-mail instead of user ID
  //DEBUG Uncomment below:
  //console.log("Logged into Realm as:", user.id);
   else {return (
      <div>
        <h1>Logged into Realm as: {user.id}</h1>
      </div>
    );}
  }

/*
Primary function for handling user login.  Utilize Realm API
to allow a user to login with the email and password variabls passed through
the login form on the page.  
*/
export async function LoginEmail(email, password) {
    //const REALM_APP_ID = "simple-kanban-api-xhnql";
    //const app = new Realm.App({ id: REALM_APP_ID });  
    const credentials = Realm.Credentials.emailPassword(email, password);
    try {
        const user = await app.logIn(credentials)
        var RealmUser = app.currentUser; // This may not work
        console.log("Login was successful! Welcome user:", user.id)
        //console.log(RealmUser)
        return RealmUser.id
    }
    catch(err) {
        console.log("Unable to log in - ERROR:", err)
        return false
    }
}

/*Anonymous Log In Function
//This function was used to dev/ test only, we are leaving it for now
In case we want to create a temporary "demo account" that will reset itself 
that shows the board off before prompting a user to create an account.
To-do:
1st time accessing page -> anon login -> user creates a few tasks -> system prompts them to create an account
Future logins:
if site has been visited -> automatically login (if remember me was clicked) OR prompt for login
*/

/*function LoginAnon({ setUser }) {
    const loginAnonymous = async () => {
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user);
    };
    return <button onClick={loginAnonymous}>Log In</button>;
  }*/


/*
Logout Function 
  This function logs the user out upon clicking logout,
  several other items of importance are done, so this function is part of
  a larger Log Out within the app.jsx file.
*/
export async function LogOut(){
    //const REALM_APP_ID = "simple-kanban-api-xhnql";
    //const app = new Realm.App({ id: REALM_APP_ID });
    //console.log('DEBUG1', app, app.currentUser)
    const result = await app.currentUser.logOut()
    .then(result => console.log("Successfully logged out.")) //app.currentUser))
    .catch(err => console.log("Unable to log out", err))
    //console.log('DEBUG2', app, app.currentUser)
    return result;
}


/* Auth Provider function
  This function periodically checks and sets the user to the one within the app.
  It is running continously while a user is logged in. 
  This is generally meant for an anonymous user - it also allows for logout and login with
  different users.  
  to-do: setUser currently is unused, but the code requires a 2nd arg to function properly.
  We need to remove the realm id and show either a user name or something else to show that they are logged in.

  */
const AuthProvider = () => {
    const [user, setUser] = React.useState(app.currentUser)
    //DEBUG
    //console.log("current App User:", user.id)
    
    // for debugging // console.log("MongoRealm says: ", user, setUser);
    // if we need to bring anonymous log on back : <LoginAnon setUser={setUser} />}
    return (
      <div>
      <UserDetail user={user} /> 
      </div>)
  
};

export default AuthProvider;

