import React from "react";
import styled from "styled-components";
import Task from "./task.jsx";
import { Droppable } from "react-beautiful-dnd";
import {KanBin} from "./RecycleBin";

/*
This render the right sidebar column with the trash can.
This is also a functional drag and drop column which requires some additional love
so we create our own component for it.

A lot of the styling and the render itself has been customized to make it so that it 
doesn't animate or stretch out on hovering with a draggable item. This is all 
in order to improve useability.
*/


//Using styled components we can edit the column appearance
//directly in the react file.
const Container = styled.div`
  margin-top: 10px;
  position: flex;
  border-left: solid 1px;
  margin-bottom: 7px;
  
  width: 15%;
`;
const Title = styled.h3`
  position: sticky;
  top: 4em;
  padding-top: 16px;
  padding-left: 0;
  padding-bottom: 5px;
  text-align: center;
  font-family: 'Comfortaa', cursive;
  font-size: 24px;

`; 

const DeleteList = styled.div`
transition: background-color 0.3s ease;

background-color: ${props => (props.isDraggingOver ? 'rgba(255, 0, 0, 0.3)' : 'opaque')};
height: 100%;
min-height: 960px;
margin-top: 1px;
margin-bottom: 0px;
font-size: 26px;
flex-grow: 1;
transition: background-color 0.3s ease;
position: sticky;
opacity: ${props => (props.isDraggingOver ? '100%' : '45%')}
`;

/*
Class is designed to mimic the column file with a few differences.
*/
export default class TrashColumn extends React.Component {
  constructor(props) {
    super(props)
       this.myRef = React.createRef();
       this.id = this.props.key;
    
  }
  
  //Unused except for debugging
  returnref(){
    console.log(this.id)
  }
  
  /*
  Render description
  Creates a drag to delete / garbage can image that is opaque. Becomes less 
  opaque when hovering over.

  The delete column is a 4th column that does not render any tasks and 
  contains no placeholder. It is to allow a droppable space to move tasks
  to the invisible delete column.

  Dev Note:
  Adds a droppable container for deletion which is actually another column. 
  Here we intentionally remove the placeholder item from react-beautiful-dnd
  so as to disable the columns resizing to fix the tasks.
  
  Initially, I thought this was a fun quirk, but it was quickly pointed out to me in user
  feedback that while it is fun to play with, it makes the app more difficult to use for its 
  intended purpose.
  */
  render() {
    return (
      
      <Container>
      <DeleteList>
      
      
      <Title>drag to<p></p>delete<p></p><p></p><KanBin></KanBin></Title>  
        
        
        <Droppable droppableId={"trashcolumn"} ignoreContainerClipping={false}>
          {(provided, snapshot) => (
            
            <DeleteList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
              {/* Removed this code
              as we do not need to render any deleted tasks from the delete column:
              */
              /*{this.props.tasks.map((task, index) => (
                <Task key={task.id} task={task} index={index} />
              ))}*/}
              {/*This makes the column resize when dragging over it*//*provided.placeholder*/}
            </DeleteList>
            
            
          )}
        
        </Droppable>
        
        
      </DeleteList>
      </Container>
      
    );
  }
}
