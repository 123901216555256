import React, { Component } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'
//Here we are creating a child component to show a spinning arrow for 2-3 seconds on login
//we will add it into the Login component onclick to change its status for 2 seconds when
//login is clicked. 


//This uses the fontawesome library to add some animation in to show the user we are logging in
//With the animated spinner icon.
library.add(faSpinner)


export default class LoadingButton extends Component {
    state = {
        loading : false
    }

        //When clicked we change loading  to true within the state of the 
        //child component.  It reverts back to false after a 3 second timeout.
        //To-do: make the toast reactions / this line up, so the button doesn't keep
        //animating when there is a login rejection.
        showLoad = () => {
            this.setState({ loading: true });
            setTimeout (() => {
                this.setState({loading: false})
            }, 3000)
        }

    /* Render description:
        Returns the login button with spinner if the the loading condition is true,
        otherwise, returns the button with no icon/animation.
    */
    render() {
        
        const {loading} = this.state;
        return (
                
                <button type={this.props.type} onClick={this.showLoad}>
                
                {loading && <span><FontAwesomeIcon icon={faSpinner} spin style={{ color: 'white' }} /> Logging In...</span> }
                {!loading && <span>Login</span>}
                </button>
                
            )
    }
}
