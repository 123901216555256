import React from 'react';
import ReactDOM from 'react-dom';
import '@atlaskit/css-reset'
import App from './App';
import * as serviceWorker from './serviceWorker';

/*
Index placeholder js file made from the create-react-app.
This simply renders everything within the app component file,
puts react in strict mode, it also allows a service worker which adds
additional functionality and security from React. 
*/


ReactDOM.render(
<React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
