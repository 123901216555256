import React from "react";
import styled from "styled-components";
import Task from "./task.jsx";
import { Droppable } from "react-beautiful-dnd";

/*
Column React Component
  Contains all the column information including styles and rendering.
  For the 3 primary columns within the app.

  To-Do: (Future)
  -Add functionality to create / add / remove columns
  -Make columns draggable
  -Style task list items rotation css by column to increase randomness of task orientation.
*/


//Column CSS with styled components
//Actual container that holds the columns
const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  border-right:solid 4px black;
  border-left: solid 1px black;
  
  justify-content: center;
  width: 50%;
  
  flex-direction: column;
  
`;
//Header titles of the columns
const Title = styled.h3`
  padding-top: 16px;
  padding-bottom: 16px;
  /*padding-left: 25%;*/
  text-align: center;
  font-family: 'Rock Salt', cursive;
  font-size: 48px;
  align-content: center;
  
`;
//The container that holds a tasklist that can be dragged over
const TaskList = styled.div`
  padding: 12px;
  padding-left: 20%;
  border-top: 4px black solid;
  transition: background-color 0.3s ease;
  background-color: ${props => (props.isDraggingOver ? 'lightblue' : 'white')};
  height: 100%;
  min-height: 960px;
  minimum-width: 225px;
  font-family: 'Shadows into Light Two', cursive;
  font-size: 26px;
  flex-grow: 1;
`;

//Column react component class.
//The properties it holds are an innerRef for React beautiful dnd
//As well as a key also needed for dnd functionality
export default class Column extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.id = this.props.key;

  }
  //Unused, for debug only.
  returnref() {
  console.log(this.id)
  }

  /*
  Render overview
  This creates a column with a droppable space, passing the private react-dnd
  variables to the column and the elements that render inside of it (tasks).
  We also include a placeholder that allows for the animation when moving tasks around.
  Tasks are mapped to their column based on their index location within the app / db data state.
  */
  render() {
    return (
      <Container>
        <Title contentEditable={true} spellCheck={false} id={this.props.column.id} title={this.props.column.title} onFocus={this.props.onFocus} onBlur={this.props.onBlur} suppressContentEditableWarning={true}>
        {this.props.column.title}
        
        </Title>

        <Droppable droppableId={this.props.column.id}>
          {(provided, snapshot) => (
            <TaskList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
              {this.props.tasks.map((task, index) => (
                <Task key={task.id} task={task} index={index} onFocus={this.props.onFocus} onBlur={this.props.onBlur}/>
              ))}
              {provided.placeholder}
            </TaskList>
          )}

        </Droppable>
      </Container>
    );
  }
}

