import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";

/*
Task React Child component

This contains the rendering for tasks objects as well as some important 
properties for draggability and also / stylings to make them appear as post it notes.
*/



  //Post-it note style container css
  /*Note:
        The following CSS3 was utilized and loosely based off
         a tutorial for the creation of "post-it" note tasks via 
        the Envato Tuts+ website by Chris Heilmann
        with minor edits to create a desired effect.
        Tutorial obtained from the following URL:
         https://code.tutsplus.com/tutorials/create-a-sticky-note-effect-in-5-easy-steps-with-css3-and-html5--net-13934  */
const Container = styled.li`
  
  margin-bottom: 15px;
  background-color: lightyellow;
  box-shadow: ${props => (props.isDragging ? '5px 5px 7px grey': '0 4px 8px 0 rgba(0,0,0,0.2)')};
  transform: ${props => (props.isDragging ? 'scale(1.15)' : 'scale.1.15')};
  padding: 12px;
  background:#ffc;
  display: block;
  height:9em;
  width: 9em;
  padding:1em;
  :hover{
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2);
  transform: scale(1.15);
  margin-bottom: 1em;
  margin-top: .5em;
  }
  
  Ensure that whitespace is preserved with tasks
        /* css-3 */
  white-space: pre;
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  overflow-wrap: break-word;
  word-wrap: break-word;
  
  /* Firefox */
  -moz-box-shadow:${props => (props.isDragging ? '5px 5px 7px grey': '0 4px 12px 0 rgba(0,0,0,0.2)')};
  /* Safari+Chrome */
  -webkit-box-shadow: ${props => (props.isDragging ? '5px 5px 7px grey': '0 4px 8px 0 rgba(0,0,0,0.2)')};
  /* Opera */
  box-shadow: ${props => (props.isDragging ? '5px 5px 7px grey': '0 4px 8px 0 rgba(0,0,0,0.2)')};
  
  -webkit-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};
  -o-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};
  -moz-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};

/*These properties below are for making the tasks slightly rotate in different ways
to-do: make it so that they are not perfectly lined up accross columns with the same
degree rotations. */

  &:nth-child(even) {
    -o-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    -webkit-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    -moz-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    position: relative;
    :hover{
    box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2);
    transform: scale(1.15);
    margin-top: .5em;
    position: relative;
    z-index: 5;
  }

  &:nth-child(3n) {
    -o-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};
    -webkit-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};
    -moz-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(0deg)')};
    position: relative;
    :hover{
    box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2);
    transform: scale(1.15);
    margin-top: .5em;
    position: relative;
    z-index: 5;
  }
  &:nth-child(5n) {
    -o-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    -webkit-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    -moz-transform: ${props => (props.isDragging ? 'rotate(-2deg)' : 'rotate(1deg)')};
    position: relative;
    :hover{
    box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2);
    transform: scale(1.15);
    margin-top: .5em;
    position: relative;
    z-index: 5;
  }
  
  }
/*White space stylings below to allow people to format their tasks text
and give them a little control.  To-do: implement a character maximum so that
text cannot write beyond an actual task. */
`
const DivStyle2 = styled.div`
    :hover{
      cursor: text;
    }
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;      
    overflow-wrap: break-word;
    
`;

const DivStyle = styled.div`
  :hover{
    cursor: text;
  }
  font-weight: bold;
`



/*
Task component render overview
  Provides taskId and index to each task for react-beautiful-dnd needed props.
  
  The tasks render based on the list that is passed to them from the parent column.
  They each contain two different data components (their title and their content)
  both are seperately editable and contain needed properties to do that).

  //Todo - change this to be this.props.task.content to fix tooltips then modify handle new change function
 */
export default class Task extends React.Component {
  
  render() {
      return (
      
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
        <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
        >   
            
            
            <DivStyle
            contentEditable={true}
            spellCheck={false}
            suppressContentEditableWarning={true}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            id={this.props.task.id}
            title={this.props.task.title}
            >
            {this.props.task.title}
            
            </DivStyle>
            <p></p><p></p>
            
            <DivStyle2
            contentEditable={true}
            spellCheck={false}
            suppressContentEditableWarning={true}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            id={this.props.task.id}
            title={"taskContent"} 
            content={this.props.task.content}>
            {this.props.task.content}
            </DivStyle2>
            
            
        </Container>
        )}
      </Draggable>
      
      
    );
  }
}
