//Important:
/*
This file contains the JSON object that creates a users initial board.

To-do: modify the example tasks to be a "tutorial" of sorts to explain how the app works.
*/

const data = {
//All board tasks are contained within this file.  The id that they have matches to
//Column taskId arrays to determine where they aer on the board.
tasks: {
    'task-1': {id: 'task-1', title: "Welcome to Simple Kanban.", content: 'A productivity app by Ryan Dalton, powered by ReactJS and MongoAtlas'}, 
    'task-2': {id: 'task-2', title: "Try making and editing a few tasks...", content: "It's as easy as clicking on them! Columns and the board title can be edited too."},
    'task-3': {id: 'task-3', title: "Drag to move and delete tasks.", content: "Your board will be saved for the next time you visit."},
    
},
//Columns are contained as their own array with nested objects/taskId lists.
columns: {
    'column-1': {
        id: 'column-1',
        title: 'to-do',
        taskIds: ['task-1', 'task-2',]
    },
    'column-2': {
        id: 'column-2',
        title: 'doing',
        taskIds: []
    },
    'column-3': {
        id: 'column-3',
        title: 'done',
        taskIds: ['task-3']
    },
    //The trash column  is made but doesn't store any information outside of a local session
    //It is purely to provide undo and drag and drop functionality.
    //To-do - delete the taskId array upon logout/ board save to prevent DB data from growing unnecessarily.
    'trashcolumn':{
        id: 'trashcolumn',
        title: 'deleted',
        taskIds: []
    },
},
//The column order determines how columns are drawn
//This is important for future drag and drop functionality if columns are to be movable.
columnOrder: ['column-1', 'column-2', 'column-3'],
boardTitle: "My Kanban Board",
//0000 is a placeholder, it is replaced upon initial login with the user's realm id.
userId: "0000",
//If false is showing, the actual board state will not render, this is for logout/login purposes
auth: false,
email: '',
//To-do - need to remove password from the datastate, as it is unnecessary and insecure.
password: ''
}



export default data;



