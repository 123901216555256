import * as Realm from "realm-web";

/*
This contains our CRUD operations and database utilization with MongoDB/Realm.
This is all kept fairly simply with immutable data states.
Whenever we update the database, we replace the entire board state.

Specific data manipulations that are much more complex and tedious to maintain are found
    in a seperate functions folder within the realm app.  Unsurprisingly, they are
    all unused :)
*/



//Unused global variables (they are utilized in the auth provider app and were
//Creating multiple instances of login)
//var REALM_APP_ID = "simple-kanban-api-xhnql";
//var app = new Realm.App({ id: REALM_APP_ID });

//Creates a timeout for these specific operations
const appConfig = { 
    id: "simple-kanban-api-xhnql",
    timeout: 1000,
}

//This creates an initial board for a user the first time they log in.
export async function MongoInsert(user, data){
   
    try{
    const app = new Realm.App(appConfig);
    //const userId = user;
    //console.dir(userId);
    const mongo = app.services.mongodb("mongodb-atlas");
    const coll = mongo.db("Simple-Kanban").collection("Kanban")
    
    data = await coll.insertOne(data);
    //console.dir(data);
    

}
catch(err){
    console.log("Unable to insert initial board state ERROR:", err)
}}

/*
Update operations for the board.  Whenever we confirm that there is new 
data this runs so as to find and replace the user's board state.

See below for the function that checks to see if there is anything that is needed to update.
*/
export async function MongoUpdateState(user, data){
    //DEBUG
    //console.log("UPDATE STATE PASSED DATA:", user, data )
    try{
    const app = new Realm.App(appConfig);
    const userId = user;
    //console.dir(userId);
    const mongo = app.services.mongodb("mongodb-atlas");
    const coll = mongo.db("Simple-Kanban").collection("Kanban")
    data = await coll.findOneAndReplace({userId: userId}, data);
    //console.dir(data);
    return data;

}
catch(err){
    console.log("Error, unable to update state:", err)
}}

// to maintain board state with the DB but not have it constantly updating every single move.
//Implement a checker that compares state to the last uploaded state every 10 seconds

export function SyncChecker(user, lastUploadedState, currentState) {
    //DEBUG-
    //console.log("Checker: Current State", currentState)
    //console.log("Checker: Last State:", lastUploadedState)

    //If no changes have been made (board is idle) - nothing to update
    if (lastUploadedState === currentState){
        //uncomment to debug
        //console.log("No changes made , no upload necessary") 
        return
    }
    //If changes were made:
    else if (lastUploadedState !== currentState ) {
        //DEBUG
        //console.log("Changes detected!")

        //If a statechange has been made, sync the board to the entry
        MongoUpdateState(user, currentState)
            .then(() => console.log("Synced Board.")) //show us the mongo response ToDo - remove response
            .catch(err => console.log("Could not sync state", err)) //explain what happened
           
    }
}

/*
This gets the board for the current user based on their realmId on login and loads
up their board with its last updated state.
*/
export async function MongoRetrieve (user) {
    try{
        const app = new Realm.App(appConfig);
        const userId = user;
        //DEBUG
        //console.dir(userId);
        const mongo = app.services.mongodb("mongodb-atlas");
        const coll = mongo.db("Simple-Kanban").collection("Kanban")

        const data = await coll.findOne({userId: user}); //Retrieve the users data
        //DEBUG
        //console.dir(data);
        
        return data //DEBUG sends data instead of true - needed for app functions in seperate file
    } 
    catch(err){
        console.log("Unable to retrieve user table information. ERROR:", err)
    }


}



