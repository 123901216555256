import React, { Component } from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {faStickyNote} from '@fortawesome/free-solid-svg-icons'

/*
This creates a rarely seen suggestion that appears in the background if a user has
an empty board with no tasks.  Uses fontawesome for post-it icon.

To-do: get a quick fade-in to occur to prevent the render from looking somewhat jarring.
*/


const Suggestion = styled.div`

    position: absolute;
    
    margin: auto;
    top: 50%;
    left: 0;
    right: 0;
    width: 50%;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    border: solid 3px;
    background-color: white; 
    font-family: 'Comfortaa', cursive;
    font-size: 25px;
`

/*
Only renders if tasksEmpty is true - app component checks and passes this in.
*/
export default class Tooltip extends Component {
    
    render() {
        
        if (this.props.tasksEmpty === true){
            return (
                <Suggestion>
                <p>Click +<FontAwesomeIcon icon={this.props.icon}/> to add some new tasks to the board!</p>
                </Suggestion>
            )}
        else{
            return(
                <div></div>
                )}
        }
    }