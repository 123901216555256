import React from "react";
import styled from "styled-components";
//import ReactDOM from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {faStickyNote} from '@fortawesome/free-solid-svg-icons'


//Styled components for button styling - 
//This contains styles for the add new task and also the undo buttons.
//Also utilizes styles for the login / logout button.
const KanbanButton = styled.button`
  margin-top: 25px;
  position: sticky;
  position: -webkit-sticky;
  top: ${props => (props.topValue)};
  padding: 28px;
  background-color: #779BC3;
  font-family: "Comfortaa", cursive;
  font-size: 28px;
  border-radius: 25px;
  text-align: center;
  border: 0px;
  color: white;
  box-shadow: 0 6px 8px 0 #666;
  transition: all 0.1s ease-in-out;
  :hover{
  box-shadow: 0 6px 14px 0 #666;
  transform: scale(1.05);
  }
  
`;


//KanButton simply returns whatever props are passed to it, including the name
export class KanButton extends React.Component {


    render() {
        return(
        <KanbanButton 
        onClick={() => this.props.onClick()} 
        topValue={this.props.topValue}>
        <FontAwesomeIcon icon={this.props.icon} size={this.props.size}/>
        {this.props.name}
        </KanbanButton>
        )
    }
    
}

